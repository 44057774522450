import React, { useState, useEffect, useCallback } from "react";
import "./ProjectList.scss";
import { PostListItem } from "../../Blog/PostListItem";
import { ProjectListNavigation } from "../ProjectListNavigation";
import { Carousel } from "components";

import { graphql } from "gatsby";

export const ProjectList = ({ posts, categories }) => {
  const carouselOptions = {
    perView: 4,
    type: "slider",
    gap: 16,
    swipeThreshold: true,
    breakpoints: {
      1080: {
        perView: 2,
      },
      700: {
        perView: 1,
      },
    },
  };

  // returns all the posts which matches the wordpress_id
  const reducedPosts = useCallback(
    (wp_id) => {
      return posts.reduce(function (result, option) {
        if (option.node.project_category.includes(wp_id)) {
          return result.concat(option.node);
        }
        return result;
      }, []);
    },
    [posts]
  );

  const [veterinaryItems, setVeterinaryItems] = useState({});
  const [childcareItems, setChildcareItems] = useState({});
  const [officeItems, setOfficeItems] = useState({});
  const [medicalItems, setMedicalItems] = useState({});

  useEffect(() => {
    setVeterinaryItems(reducedPosts(8));
    setChildcareItems(reducedPosts(15));
    setOfficeItems(reducedPosts(16));
    setMedicalItems(reducedPosts(14));
  }, [reducedPosts]);

  return (
    <>
      <ProjectListNavigation categories={categories} />
      <section className="project-list layout">
        <div className="project-list-container">
          {veterinaryItems.length > 0 && (
            <div className="industry-container">
              <h2>Veterinary</h2>
              <Carousel options={carouselOptions} id="vetinary" fullWidth>
                {Object.keys(veterinaryItems).length > 0 &&
                  veterinaryItems?.map((slide, index) => (
                    <PostListItem
                      categories={categories}
                      key={index}
                      data={slide}
                    />
                  ))}
              </Carousel>
            </div>
          )}

          {childcareItems.length > 0 && (
            <div className="industry-container">
              <h2>Childcare</h2>
              <Carousel options={carouselOptions} id="childcare" fullWidth>
                {Object.keys(childcareItems).length > 0 &&
                  childcareItems?.map((slide, index) => (
                    <PostListItem
                      categories={categories}
                      key={index}
                      data={slide}
                    />
                  ))}
              </Carousel>
            </div>
          )}

          {officeItems.length > 0 && (
            <div className="industry-container">
              <h2>Office Workplaces</h2>
              <Carousel options={carouselOptions} id="medical" fullWidth>
                {Object.keys(officeItems).length > 0 &&
                  officeItems?.map((slide, index) => (
                    <PostListItem
                      categories={categories}
                      key={index}
                      data={slide}
                    />
                  ))}
              </Carousel>
            </div>
          )}

          {medicalItems.length > 0 && (
            <div className="industry-container">
              <h2>Medical & Wellness</h2>
              <Carousel options={carouselOptions} id="office" fullWidth>
                {Object.keys(medicalItems).length > 0 &&
                  medicalItems?.map((slide, index) => (
                    <PostListItem
                      categories={categories}
                      key={index}
                      data={slide}
                    />
                  ))}
              </Carousel>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    wordpress_id
    id
    project_category
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    path
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
