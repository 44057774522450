import React from "react";
import { GatsbyLink } from "components";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import "./PostListItem.scss";
import { ReactComponent as Arrow } from "images/icons/arrow.svg";
import { dateI18n } from "@wordpress/date";
import {
  decodeEntities,
  getCategory,
  getServiceCat,
} from "../../../utils/helpers";

export const PostListItem = ({
  data: { excerpt, title, slug, path, featured_media, project_category, date },
  categories,
  dateFormat,
  showDate,
}) => {
  const dateFormatted = dateFormat && date && dateI18n(dateFormat, date);

  return (
    <GatsbyLink to={path} className="post-list-item">
      <div className="image-container">
        <ImagePass src={featured_media} />
      </div>
      {categories && (
        <span className="eyebrow">
          {getCategory(getServiceCat(project_category), categories)}
        </span>
      )}
      {showDate && dateFormatted && (
        <span className="eyebrow">{dateFormatted}</span>
      )}

      <h3 className="project-heading">{decodeEntities(title)}</h3>

      <RenderContent content={excerpt} />
      <span className="arrow">
        <Arrow />
      </span>
    </GatsbyLink>
  );
};
